import { useContext, useState } from "react";
import QuestionContext from "../../contexts/QuestionContext";
import Controls from "./Controls";
import Head from "./shared/Head";
import List from "./shared/List";
import styles from "./Team.module.scss";
import data from "./utils/teams.json";
import { getNewContext } from "./utils/utils";
import useMatchMedia from "../../utils/useMatchMedia";
import cx from "classnames";

function Image(props) {
	const [error, setError] = useState(false);
	const errorText = props.logo.replace("SchoolLogos/", "");

	return props.logo ? (
		<>
			<img
				className={cx(styles.image, { [styles.error]: error })}
				src={`/assets/${props.logo}`}
				alt={props.shortname}
				onError={() => setError(true)}
			/>
			{error && (
				<div className={styles.error} data-error={errorText}>
					<span className={styles.errorA}>{errorText}</span>
					<span className={styles.errorB}>
						<span>{errorText}</span>
					</span>
				</div>
			)}
		</>
	) : null;
}

// Note: props.static comes from the /teams page

function Team(props) {
	const { context, setContext, state, setState } = useContext(QuestionContext);
	const teams = props.static
		? data
		: data.filter((team) => team.State === state.teamState);

	const handleClick = (item) => {
		if (props.static) return null;
		const newContext = getNewContext(context, state, item.PrimaryKey);
		setContext(newContext);
		if (props.id === "team") {
			setState((prevState) => ({
				...prevState,
				team: item,
			}));
		}
	};

	const isLargeScreen = useMatchMedia("(min-width: 1280px)");

	return !isLargeScreen ? (
		<List
			items={[{ items: teams }]}
			onClick={handleClick}
			selectedKey="PrimaryKey"
			{...props}>
			{(team) => <>{team.Name}</>}
		</List>
	) : (
		<div className={cx(styles.main, { [styles.static]: props.static })}>
			<Head className={styles.head} label={props.label} />
			<div className={styles.teams}>
				{teams.map((item, index) => {
					const isDismissed = props.value && props.value !== item.PrimaryKey;
					return (
						<button
							onClick={() => handleClick(item)}
							key={index}
							className={cx(styles.item, {
								[styles.dismissed]: isDismissed,
							})}>
							<div className={styles.nickname}>
								{item.Nickname || "[Nickname]"}
							</div>
							<div className={styles.shortname}>
								{item.ShortName || "[ShortName]"}
							</div>
							<div className={styles.logo}>
								<Image logo={item.Logo} shortname={item.ShortName} />
							</div>
						</button>
					);
				})}
			</div>
			<div className={styles.mask}></div>
			<Controls
				className={styles.controls}
				canProceed={props.value}
				{...props}
			/>
		</div>
	);
}

export default Team;
