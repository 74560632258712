import styles from "./Figures.module.scss";
import cx from "classnames";

function FigureBody({ modal }) {
	return (
		<div className={cx(styles.main, { [styles.modal]: modal })}>
			<svg viewBox="0 0 456 542" fill="none">
				<path
					d="M294.291 25.2499C294.291 13.0602 305.607 4.02377 317.495 6.72081L439.774 34.4632C448.428 36.4265 454.57 44.1185 454.57 52.9923V139.116C454.57 151.305 443.254 160.342 431.367 157.645L309.087 129.902C300.433 127.939 294.291 120.247 294.291 111.373V25.2499Z"
					className={styles.item}
				/>
				<rect
					x="120.23"
					y="382.688"
					width="57.546"
					height="158.316"
					rx="28.773"
					className={styles.item}
				/>
				<rect
					x="44.9805"
					y="363.057"
					width="57.546"
					height="158.316"
					rx="28.773"
					className={styles.item}
				/>
				<rect
					x="1.79297"
					y="158.9"
					width="57.546"
					height="158.316"
					rx="28.773"
					className={styles.item}
				/>
				<path
					d="M26.0039 178.383C26.0039 154.215 48.1348 136.1 71.8268 140.877L155.584 157.763C173.441 161.364 186.283 177.054 186.283 195.27V362.513C186.283 386.682 164.152 404.796 140.46 400.019L56.7033 383.133C38.8465 379.533 26.0039 363.842 26.0039 345.626V178.383Z"
					className={styles.item}
				/>
				<rect
					x="158.183"
					y="190.963"
					width="57.546"
					height="158.316"
					rx="28.773"
					className={styles.item}
				/>
				<circle cx="106.143" cy="91.81" r="64.4352" className={styles.item} />
				<rect
					x="141.194"
					y="58.564"
					width="9.00006"
					height="20.9584"
					rx="3"
					transform="rotate(-7.30292 141.194 58.564)"
					className={styles.fill}
				/>
				<path
					d="M148.979 103.013C168.14 109.301 168.14 94.9294 168.14 94.9294"
					strokeWidth="5.98504"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={styles.stroke}
				/>
				<path
					d="M223.526 174.236L255.027 156.049L250.459 173.261L260.906 167.23L266.034 148.1L264.603 145.622L263.165 143.132L244.034 138.008L233.534 144.07L250.778 148.689L219.277 166.877L223.526 174.236Z"
					className={styles.fill}
				/>
			</svg>
		</div>
	);
}

export default FigureBody;
