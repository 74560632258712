import { useContext } from "react";
import cx from "classnames";
import QuestionContext from "../../contexts/QuestionContext";
import Controls from "./Controls";
import { getNewContext } from "./utils/utils";
import useMatchMedia from "../../utils/useMatchMedia";
import styles from "./Selector.module.scss";
import Head from "./shared/Head";
import List from "./shared/List";

function Selector(props) {
	const { context, setContext, state, setState } = useContext(QuestionContext);

	const handleClick = (value) => {
		const newContext = getNewContext(context, state, value);
		setContext(newContext);
		if (props.id === "team-state") {
			setState((prevState) => ({
				...prevState,
				teamState: value,
			}));
		}
		if (props.id === "rival-state") {
			setState((prevState) => ({
				...prevState,
				rivalState: value,
			}));
		}
	};

	const isLargeScreen = useMatchMedia("(min-width: 1280px)");

	return !isLargeScreen ? (
		<List items={[{ items: props.options }]} onClick={handleClick} {...props}>
			{(option) => <>{option}</>}
		</List>
	) : (
		<div className={styles.main}>
			<Head className={styles.head} label={props.label} />
			<div className={styles.inner}>
				<div
					className={cx(styles.options, {
						[styles.alt]: props.options.length > 20,
					})}>
					{props.options.map((option, index) => {
						const isDismissed = props.value && props.value !== option;

						return (
							<button
								className={cx(styles.option, {
									[styles.dismissed]: isDismissed,
								})}
								key={index}
								onClick={() => handleClick(option)}>
								{option}
							</button>
						);
					})}
				</div>
			</div>
			<Controls
				className={styles.controls}
				canProceed={props.value}
				{...props}
			/>
		</div>
	);
}

export default Selector;
