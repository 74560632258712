import { useContext } from "react";
import cx from "classnames";
import QuestionContext from "../../contexts/QuestionContext";
import Layout from "./shared/Layout";
import Controls from "./Controls";
import { getNewContext, getCurrentContextIndex } from "./utils/utils";
import styles from "./Select.module.scss";

const NO_RIVAL_TEAM = "No rival team found";

function Arrow() {
	return (
		<svg viewBox="0 0 29 23">
			<path d="M2 10C1.17157 10 0.5 10.6716 0.5 11.5C0.5 12.3284 1.17157 13 2 13V10ZM28.0607 12.5607C28.6464 11.9749 28.6464 11.0251 28.0607 10.4393L18.5147 0.893398C17.9289 0.307611 16.9792 0.307611 16.3934 0.893398C15.8076 1.47919 15.8076 2.42893 16.3934 3.01472L24.8787 11.5L16.3934 19.9853C15.8076 20.5711 15.8076 21.5208 16.3934 22.1066C16.9792 22.6924 17.9289 22.6924 18.5147 22.1066L28.0607 12.5607ZM2 13H27V10H2V13Z" />
		</svg>
	);
}

function Select(props) {
	const { context, setContext, state, setState } = useContext(QuestionContext);

	const handleClick = (value) => {
		const newContext = getNewContext(context, state, value);
		setContext(newContext);
	};

	const hasHandleNext = typeof props.nextStep === "object";

	const handleNext = () => {
		const currentContextIndex = getCurrentContextIndex(context, state);
		const options = context[currentContextIndex].options;
		const index = options.indexOf(props.value);
		const nextStep = props.nextStep[index];
		setState((prevState) => ({
			...prevState,
			currentStep: nextStep,
		}));
	};

	// set the rival team
	if (props.id === "rival") {
		props.options[0] = state.team.RivalSchool || NO_RIVAL_TEAM;
	}

	return (
		<Layout
			label={props.label}
			controls={
				<Controls
					handleNext={hasHandleNext ? handleNext : undefined}
					canProceed={props.value}
					{...props}
				/>
			}>
			<div className={styles.options}>
				{props.options.map((option, index) => {
					const isSelected = props.value === option;
					const isDismissed = props.value && props.value !== option;
					return (
						<button
							className={cx(styles.option, {
								[styles.selected]: isSelected,
								[styles.dismissed]: isDismissed,
								[styles.disabled]: option === NO_RIVAL_TEAM,
							})}
							key={index}
							disabled={option === NO_RIVAL_TEAM}
							onClick={() => handleClick(option)}>
							<span>{option}</span>
							<Arrow />
						</button>
					);
				})}
			</div>
		</Layout>
	);
}

export default Select;
