import Team from "../components/questionaire/Team";
import styles from "./Questionaire.module.scss";
import data from "../components/questionaire/utils/teams.json";

function Teams() {
	return (
		<>
			<div className={styles.main}>
				<div className={styles.container}>
					<Team label={`Teams - ${data.length}`} static />
				</div>
			</div>
		</>
	);
}

export default Teams;
