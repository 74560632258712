import { useRef, useContext } from "react";
import Target from "./Target";
import { useFrame } from "@react-three/fiber";
import IntersectsContext from "../contexts/IntersectsContext";
import { gameData } from "../utils/store";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";

function Targets() {
	const targetData = [
		{ name: "target-0", x: -40 },
		{ name: "target-1", x: -20 },
		{ name: "target-2", x: 0 },
		{ name: "target-3", x: 20 },
		{ name: "target-4", x: 40 },
	];
	// const [targets, setTargets] = useState([]);
	const groupRef = useRef();
	const [context, setContext] = useContext(IntersectsContext);
	const [data, setData] = useRecoilState(gameData);
	const navigate = useNavigate();

	useFrame(({ clock }) => {
		let intersect = null;
		groupRef.current.children.forEach((child) => {
			if (child.intersects) intersect = child;
		});

		if (intersect !== context.intersects)
			setContext(
				(context) => (context = { ...context, intersects: intersect })
			);

		const count = groupRef.current.children.filter((child) => {
			return child.isGone;
		}).length;

		console.log(count);

		if (count === data.targets.length) {
			setTimeout(() => {
				navigate(data.nextPage);
			}, 1000);
		}

		// console.log(intersect);
	});

	return (
		<group ref={groupRef}>
			{data.targets?.map((item, i) => {
				const target = targetData[item];

				const a = target.x - 90;

				const d = 90;
				const x = Math.cos((a * Math.PI) / 180) * d;
				const z = Math.sin((a * Math.PI) / 180) * d;

				return (
					<Target
						key={target.name}
						name={target.name}
						index={i}
						position={[x, 0, z]}
					/>
				);
			})}
		</group>
	);
}

export default Targets;
