import { useFrame } from "@react-three/fiber";
import FaceContext from "../contexts/FaceContext";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { vectorFromAngle3D, clamp, easing } from "../utils/math";
import IntersectsContext from "../contexts/IntersectsContext";
import { Color, Vector3 } from "three";
import shaders from "../shaders";
import colors from "../utils/colors";

function TargetingRing() {
	const { faceDetect, getRotation } = useContext(FaceContext);
	const [intersectData, setContext] = useContext(IntersectsContext);
	const meshRef = useRef();
	const animPosition = useRef(new Vector3(0, 0, -90));
	const [animState, setAnimState] = useState({ direction: 0, start: 0 });
	const [stillTimer, setStillTimer] = useState(0);

	const uniforms = useMemo(
		() => ({
			color: { value: new Color(colors.white) },
			selectColor: { value: new Color("#DCFF06") },
			time: { value: window.performance.now() },
			direction: { value: animState.direction },
			start: { value: animState.start },
			timer: { value: 0 },
		}),
		[]
	);

	useEffect(() => {
		const direction = intersectData.intersects ? 0 : -1;
		setAnimState(
			(state) =>
				(state = {
					...state,
					start: window.performance.now(),
					direction,
				})
		);

		if (intersectData.intersects) {
			setStillTimer(window.performance.now());
		}
	}, [intersectData.intersects]);

	useFrame(({ camera }) => {
		if (getRotation.current) {
			const rotation = getRotation.current();
			let mesh = intersectData.intersects;
			let position = null;
			const t = window.performance.now();
			let stillProg = clamp((t - stillTimer) * 0.0005, 0, 1); // 2 secs

			// if (!mesh && stillTimer) setStillTimer(0);

			if (stillProg >= 0.5 && stillProg < 1) {
				//do nothing
				// halfway through ring animation we lock the position
				// and take a picture
				position = new Vector3().copy(meshRef.current.position);
			} else if (!mesh && stillTimer) {
				setStillTimer(0);
			}

			if (mesh) {
				position = new Vector3().copy(mesh.position);
			} else if (!position) {
				position = vectorFromAngle3D(
					rotation.x * -1,
					rotation.y * -1 - Math.PI / 2,
					89
				);
			}

			animPosition.current.lerp(position, 0.2);
			meshRef.current.position.copy(animPosition.current);
			meshRef.current.lookAt(camera.position);

			// drawing the prog ring
			uniforms.time.value = t;

			//doing the selection tactile scale
			const dir = animState.direction;
			const s = dir == -1 ? animState.start + 1000 : animState.start;
			const prog = easing.outElastic(clamp((t - s) * 0.001, 0, 1));
			let val = Math.abs(dir + prog);
			const scale = 1 - 0.2 * val;
			meshRef.current.scale.set(scale, scale, scale);

			if (mesh && stillProg >= 0.5) mesh.hasTriggered = true;
			stillProg = stillProg % 1;
			uniforms.timer.value = stillProg;
		}
	});

	return (
		<mesh ref={meshRef} position={[0, 0, -90]}>
			<planeGeometry args={[22, 22]} />
			<shaderMaterial
				uniforms={uniforms}
				fragmentShader={shaders.ringFrag}
				vertexShader={shaders.plainVert}
				transparent={true}
				depthTest={false}
			/>
		</mesh>
	);
}

export default TargetingRing;
