import styles from "./Button.module.scss";
import cx from "classnames";

function Button(props) {
	return (
		<button
			className={cx(styles.main, props.className, {
				[styles.disabled]: props.disabled,
			})}
			onClick={() => props.onClick()}
			disabled={props.disabled}>
			{props.children}
		</button>
	);
}

export default Button;
