import { useEffect, useState, useContext, useRef } from "react";
import FaceContext, { defaultData } from "../contexts/FaceContext.js";
import socketHandler from "./socketHandler.js";
import Face from "./Face.js";
import CaptureContext from "../contexts/CaptureContext.js";

function FaceHandler({ children }) {
	console.log(CaptureContext);
	const captureContext = useContext(CaptureContext);
	const faceDetect = useRef(null);
	const predictWebcam = useRef(null);
	const getRotation = useRef(null);
	const face = useRef(null);
	const [initialized, setInitialized] = useState(false);

	const [context, setContext] = useState(defaultData);

	const init = async () => {
		await captureContext.init();
		face.current = new Face(captureContext.getVideo());
		await face.current.init();

		faceDetect.current = face.current.faceDetect;
		predictWebcam.current = face.current.predictWebcam;
		getRotation.current = face.current.getRotation;
		setInitialized(true);
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<FaceContext.Provider
			value={{
				faceDetect,
				predictWebcam,
				getRotation,
				initialized,
			}}>
			{children}
		</FaceContext.Provider>
	);
}

export default FaceHandler;
