const plainFrag = `
	precision highp float;
	uniform sampler2D tDiffuse;
	uniform float time;
	uniform float isActive;
	varying vec2 vUv;

	void main() {
		vec3 c = vec3(1., 1., 0.0);
		gl_FragColor = vec4(c,1.);
	}
`;

const circleFrag = `
	precision highp float;
	uniform sampler2D tDiffuse;
	uniform vec3 color;
	uniform float isActive;
	varying vec2 vUv;

	void main() {
		float d = distance(vUv, vec2(0.5));

		float light = 0.2;
		float dark = 0.5;

		float alpha = 1. - smoothstep(0.49, .5, d);

		// float r0 = smoothstep(0.99, .1, d);
		float r1 = smoothstep(0.09, .1, d);
		float r2 = smoothstep(0.19, .2, d);
		float r3 = smoothstep(0.29, .3, d);
		float r4 = smoothstep(0.39, .4, d);
		float a = dark;
		a = mix(a, light, r1);
		a = mix(a, dark, r2);
		a = mix(a, light, r3);
		a = mix(a, dark, r4);

		a *= alpha;



		gl_FragColor = vec4(color,a);
	}
`;

const ringFrag = `
	precision highp float;
	varying vec2 vUv;
	uniform float time;
	uniform float timer;
	uniform float direction;
	uniform float start;
	uniform vec3 color;
	uniform vec3 selectColor;


	void main() {
		float pi = 3.14159265359;
		float d = distance(vUv, vec2(0.5));
		float aIn = smoothstep(0.43, 0.44, d);
		float aOut = smoothstep(0.49, 0.5, d);
		float a = 0.;

		a = mix(a, 1., aIn);
		a = mix(a, 0., aOut);

		float angle = timer;
		float fragAngle = atan(vUv.y - 0.5, vUv.x - 0.5) + pi;
		fragAngle /= 2. * pi;

		fragAngle = 1.-fragAngle;
		fragAngle -= 0.25;
		fragAngle = mod(fragAngle, 1.);

		float ringVal = smoothstep(fragAngle, fragAngle+0.01, angle);

		vec3 newColor = mix(color, selectColor, ringVal);

		float alpha = mix(0.2, 1., ringVal);

		gl_FragColor = vec4(newColor, a*alpha);
	}


`;

const pointerFrag = `

	precision highp float;
	uniform sampler2D tDiffuse;
	uniform float time;
	uniform float isActive;
	uniform vec3 color;
	varying vec2 vUv;

	void main() {
		float pi = 3.14159265359;

		float a = 0.2;
		float v = sin(vUv.y*200.)*0.99+ 1.;
		// v *= 2.;
		v = floor(v);
		a *= v;

		// if(vUv.y < 0.1) discard;
		// if(vUv.y > 0.9) discard;

		a *= clamp((sin(vUv.y * pi) - .1) * 3. , 0., 1.);


		gl_FragColor = vec4(color,a);
	}

`;

const plainVert = `
	varying vec2 vUv;

	void main() {
		vUv = uv;
		gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
	}
`;

export { plainFrag, circleFrag, ringFrag, plainVert };

export default { plainFrag, circleFrag, ringFrag, plainVert, pointerFrag };
