import { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import QuestionContext from "../contexts/QuestionContext";
import Progress from "../components/questionaire/Progress";
import Input from "../components/questionaire/Input";
import Select from "../components/questionaire/Select";
import Selector from "../components/questionaire/Selector";
import Position from "../components/questionaire/Position";
import Team from "../components/questionaire/Team";
import Confirmation from "../components/questionaire/Confirmation";
import Helper from "../components/questionaire/Helper";
import styles from "./Questionaire.module.scss";
import Sidebar from "../components/questionaire/Sidebar";

const COMPONENTS = {
	text: Input,
	email: Input,
	zipcode: Input,
	select: Select,
	selector: Selector,
	position: Position,
	team: Team,
	confirmation: Confirmation,
};

const VARIANTS = {
	enter: { opacity: 1, transition: { duration: 0.5 } },
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

function Questionaire() {
	const { context, state } = useContext(QuestionContext);

	return (
		<>
			<div className={styles.main}>
				<div className={styles.progress}>
					<Progress />
				</div>
				<div className={styles.container}>
					<AnimatePresence mode="wait">
						{context.map((item, index) => {
							const Component = COMPONENTS[item.type];
							if (!Component) return null;
							return (
								item.step === state.currentStep && (
									<motion.div
										key={index}
										initial="exit"
										animate="enter"
										exit="exit"
										variants={VARIANTS}
										style={{ height: "100%" }}>
										<Component totalSteps={context.length} {...item} />
									</motion.div>
								)
							);
						})}
					</AnimatePresence>
				</div>
				<Sidebar />
			</div>
			<Helper className={styles.helper} />
		</>
	);
}

export default Questionaire;
