import React, { useContext, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import Helment from "./Helmet";
import { Environment } from "@react-three/drei";
import styles from "./Canvas.module.css";
import { div } from "three/examples/jsm/nodes/Nodes.js";
import DataContext from "../contexts/DataContext";
import Field from "./Field";
import Targets from "./Targets";
import TargetingRing from "./TargetingRing";
import Camera from "./Camera";

import { EffectComposer, SSAO, Bloom } from "@react-three/postprocessing";
import { BlurPass, Resizer, KernelSize, Resolution } from "postprocessing";
import { useRecoilState } from "recoil";

import FaceContext from "../contexts/FaceContext";

function Frame({ start }) {
	const { predictWebcam, getRotation } = useContext(FaceContext);
	// console.log(getRotation);

	useThree(({ gl }) => {
		// gl.setPixelRatio(1);
		gl.alpha = false;
	});
	// useFrame(({ clock }) => {
	// 	// MOVE THIS OUT OF HERE
	// 	if (!predictWebcam.current) return;
	// 	predictWebcam.current();
	// 	// debugger;
	// });

	useEffect(() => {
		let frame = null;

		const fn = () => {
			if (!predictWebcam.current) {
				return (frame = window.requestAnimationFrame(fn));
			}
			// console.log("hello");
			predictWebcam.current();
			frame = window.requestAnimationFrame(fn);
		};

		frame = window.requestAnimationFrame(fn);

		return () => {
			window.cancelAnimationFrame(frame);
		};
	}, []);

	return <></>;
}

export default function () {
	const data = useContext(DataContext);

	// console.log(data);

	return (
		<div className={styles.canvas}>
			<Canvas dpr={[1, 1.2]}>
				<Frame />
				<fog attach="fog" args={["#17171b", 50, 150]} />
				<color attach="background" args={[0x000000]} />
				<Environment files={"/hdr.exr"} background={false} blur={0.5} />
				<ambientLight intensity={Math.PI / 2} />
				<Camera />
				{/* <pointLight
                    position={[-10, -10, -10]}
                    decay={0}
                    intensity={Math.PI}
                /> */}
				<Targets />
				<TargetingRing />
				<Field />
				<Helment position={[0, -4, -5]} />
				<EffectComposer enableNormalPass smaa>
					<Bloom
						intensity={3} // The bloom intensity.
						blurPass={undefined} // A blur pass.
						kernelSize={KernelSize.Large} // blur kernel size
						luminanceThreshold={0.05} // luminance threshold. Raise this value to mask out darker elements in the scene.
						luminanceSmoothing={0.03} // smoothness of the luminance threshold. Range is [0, 1]
						mipmapBlur={false} // Enables or disables mipmap blur.
						resolutionX={Resolution.AUTO_SIZE} // The horizontal resolution.
						resolutionY={Resolution.AUTO_SIZE} // The vertical resolution.
					/>
					{/* <SSAO /> */}
				</EffectComposer>
			</Canvas>
		</div>
	);
}
