export const STATES = [
	"Alabama",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

export const FOOD = [
	"BBQ Ribs",
	"Burgers",
	"Chicken",
	"Wings",
	"Chili",
	"Chips & Salsa",
	"Cookies",
	"Corn Dogs",
	"Donuts",
	"French Fries",
	"Hot Dogs",
	"Ice Cream",
	"Mac & Cheese",
	"Pizza",
	"Popcorn",
	"Sub Sandwich",
	"Sushi",
	"Tacos",
];

export const SUPERPOWERS = [
	"Flight",
	"Healing",
	"Immortality",
	"Invisibility",
	"Mind control",
	"Shape-shifting",
	"Super speed",
	"Super strength",
	"Telekinesis",
	"Telepathy",
	"Teleportation",
	"Time travel",
];

export const TRAITS = [
	"Ambitious",
	"Brave",
	"Charismatic",
	"Energetic",
	"Funny",
	"Honest",
	"Intelligent",
	"Kind",
	"Loyal",
	"Reliable",
	"Resourceful",
	"Sincere",
];

export const POSITIONS = {
	// DEFENSIVE: GRID TEMPLATE AREAS
	// "x1 x1 x2 x2 x3 x4 x5 x6 x7 x8 x8 x9 x9"
	// "y1 y1 y2 y2 y3 y4 y5 y6 y7 y8 y8 y9 y9"
	// "z1 z1 z2 z2 z3 z4 z5 z6 z7 z8 z8 z9 z9"
	defensive: [
		// ROW X
		{ label: "Defensive Back", value: "Defensive Back 1", area: "x4" },
		{ label: "Defensive Back", value: "Defensive Back 2", area: "x6" },
		// ROW Y
		{ label: "Defensive Back", value: "Defensive Back 3", area: "y1" },
		{ label: "Linebacker", value: "Linebacker 1", area: "y2", offset: true },
		{ label: "Linebacker", value: "Linebacker 2", area: "y5", offset: true },
		{ label: "Linebacker", value: "Linebacker 3", area: "y8", offset: true },
		{ label: "Defensive Back", value: "Defensive Back 4", area: "y9" },
		// ROW Z
		{ label: "Defensive Line", value: "Defensive Line 1", area: "z3" },
		{ label: "Defensive Line", value: "Defensive Line 2", area: "z4" },
		{ label: "Defensive Line", value: "Defensive Line 3", area: "z6" },
		{ label: "Defensive Line", value: "Defensive Line 4", area: "z7" },
	],
	// OFFENSIVE: GRID TEMPLATE AREAS
	// "x1 x1 x2 x2 x3 x4 x5 x6 x7 x8 x8 x9 x9"
	// "y1 y1 y2 y2 y3 y4 y5 y6 y7 y8 y8 y9 y9"
	offensive: [
		// ROW X
		{ label: "Receiver", value: "Receiver 1", area: "x1" },
		{ label: "Offensive Line", value: "Offensive Line 1", area: "x3" },
		{ label: "Offensive Line", value: "Offensive Line 2", area: "x4" },
		{ label: "Offensive Line", value: "Offensive Line 3", area: "x5" },
		{ label: "Offensive Line", value: "Offensive Line 4", area: "x6" },
		{ label: "Offensive Line", value: "Offensive Line 5", area: "x7" },
		{ label: "Receiver", value: "Receiver 2", area: "x8" },
		// ROW Y
		{
			label: "Kicker/Punter",
			value: "Kicker/Punter 1",
			area: "y1",
			offset: true,
		},
		{
			items: [
				{ label: "Quaterback", value: "Quaterback 1" },
				{ label: "Running Back", value: "Running Back 1" },
				{ label: "Running Back", value: "Running Back 2" },
			],
			style: { gridColumn: "7 / 9", gridRow: "2" }, // span y5 to y6
		},
		{ label: "Receiver", value: "Receiver 3", area: "y9" },
	],
};
