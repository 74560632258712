import { useState } from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";
import styles from "./Welcome.module.scss";
import welcome from "../assets/welcome.jpg";

function Welcome() {
	const [modalOpen, setModalOpen] = useState(false);
	return (
		<>
			<div className={styles.main}>
				<img src={welcome} />
				<div className={styles.button}>
					<Button onClick={() => setModalOpen(true)}>Let's Go</Button>
				</div>
			</div>
			<Modal
				open={modalOpen}
				text="Tap your ticket below to get started"
				button={<Button href="/start">Got it!</Button>}
			/>
		</>
	);
}

export default Welcome;
