import { useState } from "react";
import useBrowserLayoutEffect from "./useBrowserLayoutEffect";

export default function useMatchMedia(mediaQueryString, initialState = false) {
	const [state, setState] = useState(initialState);

	useBrowserLayoutEffect(() => {
		if (window.matchMedia) {
			const mediaQueryList = window.matchMedia(mediaQueryString);

			const updateState = () => {
				setState(mediaQueryList.matches);
			};
			updateState();

			mediaQueryList.addListener(updateState);
			return () => {
				mediaQueryList.removeListener(updateState);
			};
		}
	}, [mediaQueryString]);

	return state;
}
