import { useContext, Fragment } from "react";
import QuestionContext from "../../contexts/QuestionContext";

function Helper({ className }) {
	const { context, state } = useContext(QuestionContext);

	return (
		<div className={className}>
			<pre>
				Current Step: <span>{state.currentStep}</span>
			</pre>
			{context.map((step, index) => {
				return step.value ? (
					<Fragment key={index}>
						{index === 0 && <pre>---</pre>}
						<pre key={index}>
							Step <span>{step.step}</span>: {step.value}
						</pre>
					</Fragment>
				) : null;
			})}
		</div>
	);
}

export default Helper;
