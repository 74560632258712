import InfoBox from "./InfoBox";
import styles from "./Layout.module.scss";

function Layout({ children, modal, figure }) {
	return (
		<div className={styles.main}>
			<div className={styles.inner}>{children}</div>
			<div className={styles.info}>
				<InfoBox modal={modal} figure={figure} />
			</div>
		</div>
	);
}

export default Layout;
