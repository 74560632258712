import React, { useContext, useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { DoubleSide, Box3, Color } from "three";
import IntersectsContext from "../contexts/IntersectsContext";
import { OBB } from "three/addons/math/OBB.js";
import colors from "../utils/colors";
import shaders from "../shaders";

function Line() {
    const scale = 50;
    const [context, setContext] = useContext(IntersectsContext);
    const lineRef = useRef();
    const [obb, setObb] = useState(new OBB());

    const uniforms = useMemo(
        () => ({
            color: { value: new Color(colors.white) },
            opacity: { value: 0.2 },
        }),
        []
    );

    useEffect(() => {
        const lineGeo = lineRef.current.geometry;
        lineGeo.computeBoundingBox();
        obb.fromBox3(lineGeo.boundingBox).applyMatrix4(
            lineRef.current.matrixWorld
        );
        // console.log(obb);

        setContext((context) => (context = { ...context, lineBox: obb }));
    }, []);

    useFrame(() => {
        // console.log("here3");

        const lineGeo = lineRef.current.geometry;

        if (!lineGeo.boundingBox) return;
        // obb.applyMatrix4(lineRef.current.matrixWorld);
        obb.fromBox3(lineGeo.boundingBox).applyMatrix4(
            lineRef.current.matrixWorld
        );

        // console.log(obb);
    });

    return (
        <>
            <mesh
                ref={lineRef}
                position={[0, 2, -50]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[scale, scale, scale]}
            >
                <planeGeometry args={[0.01, 2]} />

                <shaderMaterial
                    transparent={true}
                    side={DoubleSide}
                    vertexShader={shaders.plainVert}
                    fragmentShader={shaders.pointerFrag}
                    uniforms={uniforms}
                    depthWrite={false}
                />
            </mesh>
            {/* <box3Helper box={obb} /> */}
        </>
    );
}

export default Line;
