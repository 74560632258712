import Canvas from "../three-components/Canvas";
import { useRecoilState } from "recoil";
import { gameData, gameRunning } from "../utils/store";
import { useEffect, useState } from "react";
import Layout from "../components/capture/Layout";
import Button from "../components/Button";
import FigureBody from "../components/capture/figures/FigureBody";

const MODAL = {
	text: "Stand with your body facing towards the screen",
	figure: FigureBody,
};

function Game() {
	const settings = {
		targets: [0, 1, 2, 3, 4],
		imageType: "front-on",
		nextPage: "/game2",
	};
	const [data, setData] = useRecoilState(gameData);
	const [isRunning, setIsRunning] = useRecoilState(gameRunning);

	useEffect(() => {
		setIsRunning(false);
		setData(settings);
	}, []);

	return (
		<Layout modal={MODAL}>
			<Canvas></Canvas>
			{/*Temporary Navigation*/}
			{/* <div style={{ position: "absolute", bottom: "60px", left: "60px" }}>
				<Button href="/game2">Next</Button>
			</div> */}
		</Layout>
	);
}

export default Game;
