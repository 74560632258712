import React, { useContext, useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
	DoubleSide,
	Box3,
	Color,
	MeshPhongMaterial,
	MeshPhysicalMaterial,
	MeshBasicMaterial,
} from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import colors from "../utils/colors";

import { circleFrag, plainVert } from "../shaders";
import IntersectsContext from "../contexts/IntersectsContext";
import { tri } from "three/examples/jsm/nodes/math/TriNoise3D.js";
// const Mat = shaderMaterial({}, vert, frag);
import CaptureContext from "../contexts/CaptureContext";
import { useRecoilState } from "recoil";
import { gameData, modelName, gameRunning } from "../utils/store";
import { animate, easing } from "../utils/math";

function Target({ position, name, index }) {
	const [context, setContext] = useContext(IntersectsContext);
	const [box3, setBox3] = useState(new Box3());
	const meshRef = useRef();
	const three = useThree();
	const [intersects, setIntersects] = useState(false);
	const { nodes, materials } = useGLTF("/assets/models/football.glb");
	// const [rotation, setRotation] = useState([0, 0, 0]);
	const [triggered, setTriggered] = useState(false);
	const ballRef = useRef();
	const { captureImage } = useContext(CaptureContext);
	const [data, setData] = useRecoilState(gameData);
	const [getModelName, setModelName] = useRecoilState(modelName);
	const [isRunning, setIsRunning] = useRecoilState(gameRunning);
	const [visible, setVisible] = useState(true);
	const [startTime, setStartTime] = useState(0);

	useEffect(() => {
		meshRef.current.geometry.computeBoundingBox();
		meshRef.current.lookAt(three.camera.position);
		meshRef.hasTriggered = false;
		meshRef.isGone = false;
		// console.log(meshRef.current);
	}, []);

	useEffect(() => {
		if (!triggered) return;

		captureImage(`${getModelName}_${data.imageType}-${index}`, getModelName);
		setStartTime(window.performance.now() + 1000);

		// meshRef.current.hasTriggered = false;
	}, [triggered]);

	useFrame(({}) => {
		if (!visible) return;
		const t = window.performance.now() * 0.01;
		const x = 0;
		const y = t * 0.1 + index;
		const z = t * 0.2 + index;

		ballRef.current.rotation.set(x, y, z);

		if (!isRunning) return;
		if (context.lineBox) {
			const meshGeo = meshRef.current.geometry;

			box3.copy(meshGeo.boundingBox).applyMatrix4(meshRef.current.matrixWorld);

			const doesIntersect = meshRef.current.hasTriggered
				? false
				: context.lineBox.intersectsBox3(box3);

			meshRef.current.intersects = doesIntersect;

			if (intersects !== doesIntersect) setIntersects(doesIntersect);

			if (meshRef.current.hasTriggered && !triggered) {
				setTriggered(true);
			}
			if (startTime) {
				const startScale = 35;
				const scale =
					(1 - animate(startTime, 100, easing.outSine)) * startScale;

				ballRef.current.scale.set(scale, scale, scale);

				if (scale == 0) {
					setVisible(false);
					meshRef.current.isGone = true;
				}
			}
		}
	});

	const material = new MeshPhysicalMaterial({
		color: new Color("#4f1e2b"),
		side: DoubleSide,
		opacity: 1,
		// depthWrite: false,
		roughness: 0.5,
		sheen: 0.5,
		metalness: 0.1,
		reflectivity: 0.8,
		iridescence: 1,
		clearcoat: 0.2,
		depthTest: true,
		transparent: true,
	});

	const stitchmat = new MeshPhysicalMaterial({
		color: new Color("#c7c7c7"),
		side: DoubleSide,
		opacity: 1,
		roughness: 0.5,
		sheen: 0.5,
		metalness: 0.1,
		reflectivity: 0.8,
		depthTest: true,
		// depthWrite: false,
		transparent: true,
	});

	const halo = new MeshBasicMaterial({
		color: new Color("#bebebe"),
		// opacity: 0.1,
		depthTest: false,
		transparent: false,
		side: DoubleSide,
	});

	return (
		<>
			<mesh
				position={position}
				ref={meshRef}
				rotation={[0, 0, 0]}
				scale={[14, 14, 14]}
				visible={false}>
				<planeGeometry args={[1, 1]} />

				<shaderMaterial
					uniforms={{
						color: { value: new Color(colors.white) },
						isActive: { value: 1 },
					}}
					fragmentShader={circleFrag}
					vertexShader={plainVert}
					transparent={true}
				/>
			</mesh>

			<group
				ref={ballRef}
				position={position}
				scale={[35, 35, 35]}
				// rotation={rotation}
				// visible={!triggered}
			>
				<group dispose={null}>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.inner_plastic.geometry}
						material={material}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.skin_4.geometry}
						material={material}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.skin_3.geometry}
						material={material}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_1.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_4.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_3.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_2.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.long_strip.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.notches.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere001.geometry}
						material={material}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere001_1.geometry}
						material={stitchmat}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere008.geometry}
						material={material}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere008_1.geometry}
						material={stitchmat}
					/>
				</group>

				{/* <box3Helper box={box3} /> */}

				<group dispose={null} scale={[1.02, 1.02, 1.02]}>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.inner_plastic.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.skin_4.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.skin_3.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_1.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_4.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_3.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.stitches_2.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.long_strip.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.notches.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere001.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere001_1.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere008.geometry}
						material={halo}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Sphere008_1.geometry}
						material={halo}
					/>
				</group>
			</group>
		</>
	);
}

useGLTF.preload("/assets/models/football.glb");

export default Target;
