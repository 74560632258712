import { Canvas, useFrame, useThree } from "@react-three/fiber";

function Camera() {
	const camera = useThree((state) => state.camera);
	camera.fov = 65;
	camera.updateProjectionMatrix();
	camera.position.z = 10;
	camera.position.y = 5;
	camera.rotation.x = -0.2;

	return <> </>;
}

export default Camera;
