import { useContext, Fragment } from "react";
import cx from "classnames";
import QuestionContext from "../../contexts/QuestionContext.js";
import styles from "./Progress.module.scss";
import { getCurrentContextIndex } from "./utils/utils.js";

function Arrow() {
	return (
		<svg viewBox="0 0 7 13">
			<path d="M1 7.25C1.41421 7.25 1.75 6.91421 1.75 6.5C1.75 6.08579 1.41421 5.75 1 5.75L1 7.25ZM0.46957 5.96967C0.176677 6.26256 0.176677 6.73744 0.46957 7.03033L5.24254 11.8033C5.53543 12.0962 6.01031 12.0962 6.3032 11.8033C6.59609 11.5104 6.59609 11.0355 6.3032 10.7426L2.06056 6.5L6.3032 2.25736C6.59609 1.96447 6.59609 1.48959 6.3032 1.1967C6.01031 0.903806 5.53543 0.903806 5.24254 1.1967L0.46957 5.96967ZM1 5.75L0.9999 5.75L0.9999 7.25L1 7.25L1 5.75Z" />
		</svg>
	);
}

function Progress() {
	const { context, state, setState, steps } = useContext(QuestionContext);

	const getCurrent = (step, nested) => {
		const isCurrentNested = nested?.includes(state.currentStep);
		const isCurrentStep = state.currentStep === step;
		return isCurrentStep || isCurrentNested;
	};

	const getCompleted = (index) => {
		const currentStep = Number(state.currentStep.replace(/[a-zA-Z]+$/, ""));
		return currentStep > index;
	};

	const currentIndex = getCurrentContextIndex(context, state);
	const currentContext = context[currentIndex];
	const hasBackBtn = currentContext.prevStep && currentContext.nextStep;

	const handleBack = () => {
		setState((prevState) => ({
			...prevState,
			currentStep: currentContext.prevStep,
		}));
	};

	return (
		<div className={styles.main}>
			<div className={styles.inner}>
				{steps.map(({ step, nested }, index) => {
					const isCurrent = getCurrent(step, nested);
					const number = `${index + 1}/${steps.length}`;
					return (
						<div
							className={cx(styles.stepNumber, {
								[styles.current]: isCurrent,
							})}
							key={index}>
							<span>{number}</span>
						</div>
					);
				})}
			</div>
			<div className={styles.inner}>
				{steps.map(({ step, nested }, index) => {
					const isCurrent = getCurrent(step, nested);
					const isComplete = getCompleted(index);
					return (
						<Fragment key={index}>
							<div
								className={cx(styles.stepBullet, {
									[styles.complete]: isComplete,
									[styles.current]: isCurrent,
								})}></div>
							<div className={styles.stepBar}></div>
						</Fragment>
					);
				})}
			</div>
			<button
				className={cx(styles.back, { [styles.active]: hasBackBtn })}
				onClick={() => handleBack()}>
				<Arrow />
				<span>Back</span>
			</button>
		</div>
	);
}

export default Progress;
