import CaptureContext from "../contexts/CaptureContext";
import { createContext, useContext, useRef, useState } from "react";
import Capture from "./Capture";

function CaptureHandler({ children }) {
	const capture = useRef(new Capture());

	return (
		<CaptureContext.Provider
			value={{
				init: capture.current.init,
				captureImage: capture.current.captureImage,
				getStream: capture.current.getStream,
				getVideo: capture.current.getVideo,
			}}>
			{children}
		</CaptureContext.Provider>
	);
}

export default CaptureHandler;
