import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import InstructionsLayout from "../components/capture/InstructionsLayout";
import FigureStart from "../components/capture/figures/FigureStart";
import Button from "../components/Button";
import styles from "./Start.module.scss";
import cx from "classnames";
import { ANIMATON_FADE_UP_SPRING } from "../utils/animations";

const TEXT = [
	"Smile!",
	"Press the buttons below the screen to move the screen so that your face fills the oval",
];

const ITEMS = [
	{
		text: "Visible face and hair back",
		image: "",
		icon: "yes",
	},
	{
		text: "No hair covering your face",
		image: "",
		icon: "no",
	},
	{
		text: "No hat or face mask",
		image: "",
		icon: "no",
	},
];

function Yes() {
	return (
		<svg viewBox="0 0 60.02 43.79">
			<path d="M9.62,17.66l12.35,12.35L50.4,1.58c1.05-1.05,2.39-1.58,4.02-1.58s2.97,.53,4.02,1.58c1.05,1.05,1.58,2.39,1.58,4.02s-.53,2.97-1.58,4.02L25.99,42.07c-1.15,1.15-2.49,1.72-4.02,1.72s-2.87-.57-4.02-1.72L1.58,25.7c-1.05-1.05-1.58-2.39-1.58-4.02s.53-2.97,1.58-4.02c1.05-1.05,2.39-1.58,4.02-1.58s2.97,.53,4.02,1.58Z" />
		</svg>
	);
}

function No() {
	return (
		<svg viewBox="0 0 52.55 52.55">
			<path d="M42.93,50.97l-16.66-16.66L9.62,50.97c-1.05,1.05-2.39,1.58-4.02,1.58s-2.97-.53-4.02-1.58c-1.05-1.05-1.58-2.39-1.58-4.02s.53-2.97,1.58-4.02L18.24,26.28,1.58,9.62c-1.05-1.05-1.58-2.39-1.58-4.02S.53,2.63,1.58,1.58C2.63,.53,3.97,0,5.6,0s2.97,.53,4.02,1.58L26.28,18.24,42.93,1.58c1.05-1.05,2.39-1.58,4.02-1.58s2.97,.53,4.02,1.58c1.05,1.05,1.58,2.39,1.58,4.02s-.53,2.97-1.58,4.02l-16.66,16.66,16.66,16.66c1.05,1.05,1.58,2.39,1.58,4.02s-.53,2.97-1.58,4.02-2.39,1.58-4.02,1.58-2.97-.53-4.02-1.58Z" />
		</svg>
	);
}

function Items() {
	const icons = {
		yes: Yes,
		no: No,
	};

	return (
		<div className={styles.items}>
			{ITEMS.map((item, index) => {
				const Icon = icons[item.icon];
				return (
					<div className={styles.item} key={index}>
						<div className={styles.image}>
							<div
								className={cx(styles.state, {
									[styles.yes]: item.icon === "yes",
									[styles.no]: item.icon === "no",
								})}>
								<Icon />
							</div>
						</div>
						<div className={styles.text}>{item.text}</div>
					</div>
				);
			})}
		</div>
	);
}

function Start() {
	const [state, setState] = useState(0);

	// const buttonProps = {
	// 	...(state === 0 && { onClick: () => setState(1) }),
	// 	...(state === 1 && { href: "/calibration" }),
	// };

	const buttonProps = {
		href: "/calibration",
	};

	const components = [
		{
			component: Items,
		},
		{
			component: FigureStart,
		},
	];

	return (
		<InstructionsLayout
			text={TEXT[state]}
			button={<Button {...buttonProps}>Got It!</Button>}>
			<AnimatePresence mode="wait" initial={false}>
				{components.map((item, index) => {
					return (
						state === index && (
							<motion.div
								key={index}
								initial="exit"
								animate="enter"
								exit="exit"
								variants={ANIMATON_FADE_UP_SPRING}
								className={styles.main}>
								<item.component />
							</motion.div>
						)
					);
				})}
			</AnimatePresence>
		</InstructionsLayout>
	);
}

export default Start;
