import IntersectsContext from "../contexts/IntersectsContext";
import { createContext, useContext, useState } from "react";

function IntersectsHandler({ children }) {
	const [context, setContext] = useState({
		mesh: null,
		intersects: null,
	});

	return (
		<IntersectsContext.Provider value={[context, setContext]}>
			{children}
		</IntersectsContext.Provider>
	);
}

export default IntersectsHandler;
