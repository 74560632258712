import { motion, AnimatePresence } from "framer-motion";
import InstructionsLayout from "./InstructionsLayout";
import Button from "../Button";
import styles from "./InfoModal.module.scss";

import { ANIMATON_FADE_UP_SPRING } from "../../utils/animations";

function InfoModal({ open, onClick, data }) {
	return (
		<AnimatePresence mode="wait">
			{open && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					variants={ANIMATON_FADE_UP_SPRING}
					className={styles.main}>
					<InstructionsLayout
						text={data.text}
						button={<Button onClick={onClick}>Got It!</Button>}
						modal>
						{data.figure && <data.figure modal />}
					</InstructionsLayout>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default InfoModal;
