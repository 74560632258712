import { createContext } from "react";
import {
	STATES,
	FOOD,
	SUPERPOWERS,
	TRAITS,
} from "../components/questionaire/utils/data";

const steps = [
	{ step: "0" },
	{ step: "1" },
	{ step: "2" },
	{ step: "3" },
	{ step: "4" },
	{ step: "5", nested: ["5a"] },
	{ step: "6", nested: ["6a", "6b"] },
	{ step: "7" },
	{ step: "8" },
	{ step: "9" },
	{ step: "10" },
	{ step: "11" },
	{ step: "12" },
	{ step: "13" },
];

const defaultState = {
	currentStep: "0",
	teamState: "",
	rivalState: "",
	team: {},
};

const defaultData = [
	{
		step: "0",
		nextStep: "1",
		prevStep: undefined,
		type: "text",
		label: "What is your first name?",
		placeholder: "First Name",
		value: "",
	},
	{
		step: "1",
		nextStep: "2",
		prevStep: "0",
		type: "text",
		label: "What is your last name?",
		placeholder: "Last Name",
		value: "",
	},
	{
		step: "2",
		nextStep: "3",
		prevStep: "1",
		type: "select",
		label: "Are you...",
		options: ["Male", "Female", "Prefer not to say"],
		value: "",
	},
	{
		step: "3",
		nextStep: "4",
		prevStep: "2",
		type: "email",
		label: "What is your email address?",
		placeholder: "Email",
		value: "",
	},
	{
		step: "4",
		nextStep: "5",
		prevStep: "3",
		type: "zipcode",
		label: "What is your current zip code?",
		placeholder: "Zip Code",
		value: "",
	},
	{
		step: "5",
		nextStep: "5a",
		prevStep: "4",
		type: "selector",
		id: "team-state",
		label: "What state is your favorite college football team from?",
		options: STATES,
		value: "",
	},
	{
		step: "5a",
		nextStep: "6",
		prevStep: "5",
		type: "team",
		id: "team",
		label: "Now, select your favorite team.",
	},
	{
		step: "6",
		nextStep: ["7", "6a"], // specify the next step for each item in the options array
		prevStep: "5",
		type: "select",
		id: "rival",
		label: "What's yout least favorite college football team?",
		options: ["", "Select a different team"], // first option is dynamically populated based of the previous step
		value: "",
	},
	{
		step: "6a",
		nextStep: "6b",
		prevStep: "6",
		type: "selector",
		id: "rival-state",
		label: "What state is your least favorite college football team from?",
		options: STATES,
		value: "",
	},
	{
		step: "6b",
		nextStep: "7",
		prevStep: "6",
		type: "team",
		id: "rival-team",
		label: "Now, select your least favorite team.",
	},
	{
		step: "7",
		nextStep: "8",
		prevStep: "6",
		type: "position",
		label: "What football position would you love to play?",
	},
	{
		step: "8",
		nextStep: "9",
		prevStep: "7",
		type: "selector",
		label: "What's your favorite football food?",
		options: FOOD,
		value: "",
	},
	{
		step: "9",
		nextStep: "10",
		prevStep: "8",
		type: "text",
		label: "What did you want to be when you grew up?",
		placeholder: "Life Goal",
		value: "",
	},
	{
		step: "10",
		nextStep: "11",
		prevStep: "9",
		type: "text",
		label: "Where did you grow up?",
		placeholder: "Childhood Hometown",
		value: "",
	},
	{
		step: "11",
		nextStep: "12",
		prevStep: "10",
		type: "selector",
		label: "If you had a superpower, what would it be?",
		options: SUPERPOWERS,
		value: "",
	},
	{
		step: "12",
		nextStep: "13",
		prevStep: "11",
		type: "selector",
		label: "What's one word your friends would use to describe you?",
		options: TRAITS,
		value: "",
	},
	{
		step: "13",
		nextStep: undefined,
		prevStep: "12",
		type: "confirmation",
	},
];

const QuestionContext = createContext(defaultData, defaultState, steps);

export { defaultData, defaultState, steps };

export default QuestionContext;
