import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import styles from "./InstructionsLayout.module.scss";
import { nl2br } from "../../utils/nl2br";

const VARIANTS = {
	enter: { opacity: 1, transition: { duration: 0.25 } },
	exit: { opacity: 0, transition: { duration: 0.25 } },
};

function InstructionsLayout({ children, text, button, modal, info = false }) {
	return (
		<div
			className={cx(styles.main, {
				[styles.isModal]: modal,
				[styles.hasInfo]: info,
			})}>
			<div className={styles.inner}>{children}</div>
			<div className={styles.controls}>
				<div className={styles.text}>{text && <h1>{nl2br(text)}</h1>}</div>
				{button}
			</div>
			<AnimatePresence mode="wait">
				{info && (
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={VARIANTS}
						className={styles.info}></motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

export default InstructionsLayout;
