import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Modal.module.scss";
import { nl2br } from "../utils/nl2br";

import { ANIMATON_FADE, ANIMATON_FADE_DOWN_SPRING } from "../utils/animations";

function Countdown({ onCountdownComplete }) {
	const [countdown, setCountdown] = useState(9);
	const [timer, setTimer] = useState(true);

	useEffect(() => {
		if (!timer) {
			return;
		}

		if (countdown === 0) {
			onCountdownComplete();
			setTimer(false);
			return;
		}

		const timeout = setTimeout(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [timer, countdown, onCountdownComplete]);

	return <h2 className={styles.timer}>{countdown}</h2>;
}

function Modal({ open, text, button, buttons, timer }) {
	const handleCountdownComplete = () => {
		setTimeout(() => {
			// Reset App ??
		}, 1000);
	};

	return (
		<AnimatePresence mode="wait">
			{open && (
				<>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATON_FADE}
						className={styles.mask}></motion.div>
					<motion.div
						initial="exit"
						animate="enter"
						exit="exit"
						variants={ANIMATON_FADE_DOWN_SPRING}
						className={styles.main}>
						<div className={styles.inner}>
							{timer && (
								<Countdown onCountdownComplete={handleCountdownComplete} />
							)}
							<div className={styles.content}>
								<h1>{nl2br(text)}</h1>
								{button}
								{!!buttons?.length && (
									<div className={styles.buttons}>
										{buttons.map((button, index) => {
											return <div key={index}>{button}</div>;
										})}
									</div>
								)}
							</div>
						</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
}

export default Modal;
