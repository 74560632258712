import InstructionsLayout from "../components/capture/InstructionsLayout";
import Button from "../components/Button";
import styles from "./HowToPlay.module.scss";
import placeholderHowToPlay from "../assets/placeholder-how-to-play.jpg";

function HowToPlay() {
	return (
		<InstructionsLayout
			text={`Move your head to break those targets! \n Keep your shoulders facing forward with a slight smile.`}
			button={<Button href="/game">Got It!</Button>}>
			<div className={styles.main}>
				<h2>How to play</h2>
				<img src={placeholderHowToPlay} />
			</div>
		</InstructionsLayout>
	);
}

export default HowToPlay;
