import { createContext } from "react";

const defaultData = {
	rotation: {
		x: 0,
		y: 0,
		z: 0,
	},
	position: {
		x: 0,
		y: 0,
	},
};

const FaceContext = createContext([defaultData, () => {}]);

export { defaultData };
export default FaceContext;
