import Button from "./shared/Button";
import styles from "./Confirmation.module.scss";

const ITEMS = [
	{
		image: null,
		text: "Watch the leaderboard to see when your experience is ready",
	},
	{
		image: null,
		text: "Start exploring the hall of fame helmet wall and find your team",
	},
	{
		image: null,
		text: "Look for our kiosks after the field, to buy merch starring you!",
	},
];

function Confirmation() {
	return (
		<div className={styles.main}>
			<div className={styles.heading}>
				AWESOME! WE’RE GETTING YOUR AI <br /> EXPERIENCE READY NOW.
			</div>
			<div className={styles.items}>
				{ITEMS.map((item, index) => {
					return (
						<div className={styles.item} key={index}>
							<div className={styles.image}>{item.image}</div>
							<div className={styles.text}>{item.text}</div>
						</div>
					);
				})}
			</div>
			<div className={styles.controls}>
				<Button>Let’s go</Button>
			</div>
		</div>
	);
}

export default Confirmation;
