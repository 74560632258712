export const getCurrentContextIndex = (context, state) => {
	return context.findIndex((context) => context.step === state.currentStep);
};

export const getNewContext = (context, state, value) => {
	const newContext = [...context];
	const currentContextIndex = getCurrentContextIndex(context, state);
	newContext[currentContextIndex] = {
		...newContext[currentContextIndex],
		value: value,
	};
	return newContext;
};
