import { useContext, useEffect, useState } from "react";
import TransitionContext from "../contexts/TransitionContext";
import styles from "./PageTransition.module.scss";

console.log(styles);
function PageTransition({ children }) {
    const [context, setContext] = useState(false);

    useEffect(() => {
        // console.log(context);
        if (context) {
            setTimeout(() => {
                setContext(false);
            }, 2000);
        }
    }, [context]);

    return (
        <TransitionContext.Provider value={[context, setContext]}>
            {children}

            <div
                className={
                    styles.pageSwipeWrap + " " + (context ? styles.enter : "")
                }
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </TransitionContext.Provider>
    );
}

export default PageTransition;
