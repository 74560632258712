import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import QuestionContext from "../../contexts/QuestionContext";
import { getCurrentContextIndex } from "./utils/utils";
import styles from "./Sidebar.module.scss";

const VARIANTS = {
	enter: { x: 0, transition: { duration: 0.25 } },
	exit: { x: "-100%", transition: { duration: 0.25 } },
};

function Sidebar() {
	const { context, state } = useContext(QuestionContext);

	const currentIndex = getCurrentContextIndex(context, state);
	const currentContextItem = context[currentIndex];
	const isActive = currentContextItem?.type === "team";

	return (
		<AnimatePresence mode="wait">
			{isActive && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					variants={VARIANTS}
					className={styles.main}>
					<div className={styles.label}>
						<span>State</span>
					</div>
					<div className={styles.state}>
						<span>{state.teamState}</span>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default Sidebar;
