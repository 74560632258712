import Button from "../components/Button";
import styles from "./Upload.module.scss";
import inputStyles from "../components/questionaire/Input.module.scss";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import { useRef } from "react";
import { modelName } from "../utils/store";

function Upload() {
	const navigate = useNavigate();
	const inputRef = useRef();
	const [mn, setModelName] = useRecoilState(modelName);

	return (
		<div className={styles.main}>
			<div className={styles.inner}>
				<input
					ref={inputRef}
					placeholder="Name"
					className={cx(inputStyles.input, styles.input)}
				/>
				<Button
					light
					onClick={() => {
						setModelName(inputRef.current.value);
						navigate("/how-to-play");
					}}>
					Next
				</Button>
			</div>
		</div>
	);
}

export default Upload;
