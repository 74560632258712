class Capture {
	imageCapture = null;
	stream = null;
	DEBUG_VIDEO = false;
	constructor() {
		this.capture = null;
		this.video = document.createElement("video");
		this.inited = false;
	}

	init = () => {
		return new Promise((resolve, reject) => {
			console.log("this.init");
			if (this.inited) {
				resolve();
				return;
			}
			this.inited = true;
			const CANVAS_WIDTH = 1280;
			const CANVAS_HEIGHT = 720;
			const constraints = {
				audio: false,
				video: {
					width: CANVAS_WIDTH,
					hight: CANVAS_HEIGHT,
					// maxWidth: CANVAS_WIDTH,
					// maxHeight: CANVAS_HEIGHT,
				},
			};

			console.log({ constraints });
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					this.video.srcObject = stream;
					this.video.play();

					if (this.DEBUG_VIDEO) {
						document.body.appendChild(this.video);

						this.video.style.position = "fixed";
						this.video.style.top = "0px";
						this.video.style.left = "0px";
						this.video.style.width = "100%";
						this.video.style.height = "100%";
						this.video.style.zIndex = "1000";
						this.video.style.opacity = "0.2";
					}

					this.stream = stream;

					const track = stream.getVideoTracks()[0];
					const capture = new ImageCapture(track);

					capture.getPhotoCapabilities().then((settings) => {
						console.log(settings);
					});
					capture.getPhotoSettings().then((settings) => {
						console.log(settings);
					});

					this.imageCapture = capture;

					resolve();
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};

	getStream = () => {
		return this.stream;
	};

	getVideo = () => {
		return this.video;
	};

	captureImage = (name, folder) => {
		return new Promise((resolve, reject) => {
			const str = "takePhoto";

			if (!this.imageCapture) return null;

			this.imageCapture[str]().then((blob) => {
				// const url = URL.createObjectURL(blob);
				// const a = document.createElement("a");
				// a.href = url;
				// a.download = name + `.jpg`;
				// a.click();
				// this.upload(blob, name + ".jpg");
				this.uploadLocal(blob, name + ".jpg", folder);
			});
		});
	};

	uploadLocal = (blob, filename, folder) => {
		const formData = new FormData();
		formData.append("folderName", folder);
		formData.append("fileInput", blob); // Your Blob data
		formData.append("fileName", filename);
		const url = "upload.php"; // Your PHP script URL

		fetch(url, {
			method: "POST",
			body: formData,
		})
			.then((response) => response.text())
			.then((result) => {
				console.log("Server response:", result);
			})
			.catch((error) => {
				console.error("Error uploading the file:", error);
			});
	};

	upload = async (blob, filename) => {
		const uploadFile = async (blob) => {
			const presignUrl = `/.netlify/functions/presign-s3-url?filename=${filename}`;
			const presignResponse = await fetch(presignUrl);
			const data = await presignResponse.json();
			const presignedUrl = data.url;

			const uploadResponse = await fetch(presignedUrl, {
				method: "PUT",
				headers: {
					"Content-Type": "image/jpeg",
				},
				body: blob,
			});

			if (!uploadResponse.ok) {
				throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
			}

			console.log("File uploaded successfully:", uploadResponse.url);
		};

		uploadFile(blob).catch((error) => console.error("Error:", error));
	};
}

export default Capture;
