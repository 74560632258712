import { motion, AnimatePresence } from "framer-motion";
import { useRecoilState } from "recoil";
import { useContext, useEffect, useState } from "react";
import CaptureContext from "../contexts/CaptureContext";
import { useNavigate } from "react-router-dom";
import { modelName } from "../utils/store";
import styles from "./Smile.module.scss";
import cx from "classnames";
import { ANIMATON_FADE_UP_SPRING } from "../utils/animations";
import Layout from "../components/capture/Layout";
import FigureBody from "../components/capture/figures/FigureBody";
import Modal from "../components/Modal";
import Button from "../components/Button";

function Camera() {
	return (
		<svg viewBox="0 0 42 38">
			<path d="M20.8333 30.2083C23.4375 30.2083 25.651 29.2969 27.474 27.474C29.2969 25.651 30.2083 23.4375 30.2083 20.8333C30.2083 18.2292 29.2969 16.0156 27.474 14.1927C25.651 12.3698 23.4375 11.4583 20.8333 11.4583C18.2292 11.4583 16.0156 12.3698 14.1927 14.1927C12.3698 16.0156 11.4583 18.2292 11.4583 20.8333C11.4583 23.4375 12.3698 25.651 14.1927 27.474C16.0156 29.2969 18.2292 30.2083 20.8333 30.2083ZM20.8333 26.0417C19.375 26.0417 18.1424 25.5382 17.1354 24.5312C16.1285 23.5243 15.625 22.2917 15.625 20.8333C15.625 19.375 16.1285 18.1424 17.1354 17.1354C18.1424 16.1285 19.375 15.625 20.8333 15.625C22.2917 15.625 23.5243 16.1285 24.5313 17.1354C25.5382 18.1424 26.0417 19.375 26.0417 20.8333C26.0417 22.2917 25.5382 23.5243 24.5313 24.5312C23.5243 25.5382 22.2917 26.0417 20.8333 26.0417ZM4.16667 37.5C3.02083 37.5 2.03993 37.092 1.22396 36.276C0.407986 35.4601 0 34.4792 0 33.3333V8.33333C0 7.1875 0.407986 6.2066 1.22396 5.39062C2.03993 4.57465 3.02083 4.16667 4.16667 4.16667H10.7292L13.3333 1.35417C13.7153 0.9375 14.1753 0.607639 14.7135 0.364583C15.2517 0.121528 15.816 0 16.4063 0H25.2604C25.8507 0 26.4149 0.121528 26.9531 0.364583C27.4913 0.607639 27.9514 0.9375 28.3333 1.35417L30.9375 4.16667H37.5C38.6458 4.16667 39.6267 4.57465 40.4427 5.39062C41.2587 6.2066 41.6667 7.1875 41.6667 8.33333V33.3333C41.6667 34.4792 41.2587 35.4601 40.4427 36.276C39.6267 37.092 38.6458 37.5 37.5 37.5H4.16667Z" />
		</svg>
	);
}

function Arrow() {
	return (
		<svg viewBox="0 0 34 50">
			<path d="M12.6223 0L12.6223 36.375L1.90735e-06 23.8125L0 35.875L14.0029 49.8809H16.8641H19.74L33.7429 35.875L33.7429 23.75L21.1206 36.375L21.1206 0L12.6223 0Z" />
		</svg>
	);
}

function Clock() {
	return (
		<svg viewBox="0 0 177 177">
			<motion.circle
				cx="88.5"
				cy="88.5"
				r="85.94"
				fill="none"
				stroke="white"
				strokeDasharray="0 0 1 14"
				strokeWidth="5"
			/>
			<path
				d="M176.94,88.46v1h-20v-1h20ZM88.39,20h1V0h-1V20Zm-.79,156.92h1v-20h-1v20ZM.07,88.64H20.07v-1H.07v1Z"
				fill="white"
			/>
		</svg>
	);
}

const MODAL = {
	text: "Nice work! now we’re going to ge some different emotions",
	figure: FigureBody,
};

const TEXT = {
	photo1: "Big Smile",
	photo2: "Shouting Face",
};

const SNAP_DURATION = 1000;

function Countdown({ state, onCountdownComplete }) {
	const [countdown, setCountdown] = useState(3);
	const [timer, setTimer] = useState(false);

	useEffect(() => {
		if (!timer) {
			return;
		}

		if (countdown === 0) {
			onCountdownComplete();
			setTimer(false);
			return;
		}

		const timeout = setTimeout(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [timer, countdown, onCountdownComplete]);

	useEffect(() => {
		if (state === "photo1" || state === "photo2") {
			setTimer(true);
			setCountdown(3);
		}
	}, [state]);

	return <h3>{countdown}</h3>;
}

function Snap({ snap, onSnapComplete }) {
	useEffect(() => {
		const timer = setTimeout(() => {
			onSnapComplete();
		}, SNAP_DURATION);
		return () => clearTimeout(timer);
	}, [snap]);

	const duration = SNAP_DURATION / 2 / 1000;

	return (
		<AnimatePresence>
			{snap && (
				<motion.div
					initial="exit"
					animate="enter"
					exit="exit"
					variants={{
						enter: { opacity: 1, transition: { duration: duration } },
						exit: { opacity: 0, transition: { duration: duration } },
						exit2: { opacity: 0, transition: { duration: 0 } },
					}}
					className={styles.snap}></motion.div>
			)}
		</AnimatePresence>
	);
}

function Smile() {
	const { captureImage } = useContext(CaptureContext);
	const navigate = useNavigate();
	const [getModelName, setModelName] = useRecoilState(modelName);

	const [state, setState] = useState("ready");
	const [snap, setSnap] = useState(false);
	const [modal, setModal] = useState("");

	const handleCountdownComplete = () => {
		const name = state === "photo1" ? "smile" : "shout";
		const imageName = `${getModelName}_${name}`;
		console.log("captureImage::", imageName);
		// @todo, @will
		captureImage(imageName, getModelName);

		setTimeout(() => {
			setSnap(true);
		}, 1000);

		setTimeout(() => {
			if (state === "photo1") {
				setState("ready2");
			} else {
				navigate("/");
			}
		}, 1000 + SNAP_DURATION);
	};

	const handleSnapComplete = () => {
		setSnap(false);
	};

	return (
		<>
			<Layout modal={MODAL}>
				<Snap snap={snap} onSnapComplete={handleSnapComplete} />
				<div className={styles.main}>
					<AnimatePresence mode="wait">
						{(state === "ready" || state === "ready2") && (
							<>
								<motion.div
									key="1"
									initial="exit"
									animate="enter"
									exit="exit"
									variants={ANIMATON_FADE_UP_SPRING}
									className={cx(styles.rowA, styles.ready)}>
									<h2>WHEN YOU’RE READY, TAP THE BUTTON BELOW AND MAKE A</h2>
									<h3>{state === "ready" ? "BIG SMILE" : "SHOUTING FACE"}</h3>
								</motion.div>
								<motion.div
									key="2"
									initial="exit"
									animate="enter"
									exit="exit"
									variants={ANIMATON_FADE_UP_SPRING}
									className={cx(styles.rowB, styles.ready)}>
									<h4>Ready? Tap here</h4>
									<Arrow />
								</motion.div>
							</>
						)}
						{(state === "photo1" || state === "photo2") && (
							<motion.div
								key="3"
								initial="exit"
								animate="enter"
								exit="exit2"
								variants={ANIMATON_FADE_UP_SPRING}
								className={styles.countdown}>
								<h2>{TEXT[state]}</h2>
								<Countdown
									state={state}
									onCountdownComplete={handleCountdownComplete}
								/>
							</motion.div>
						)}
					</AnimatePresence>
					<div className={styles.rowC}>
						<div
							className={cx(styles.button, {
								[styles.ready]: state !== "ready" && state !== "ready2",
							})}>
							<Clock />
							<button
								onClick={() => setState(state == "ready" ? "photo1" : "photo2")}
								disabled={state !== "ready" && state !== "ready2"}>
								<Camera />
							</button>
						</div>
					</div>
				</div>
			</Layout>
			<Modal
				text={`Now we need to get to know you… \n We’ll ask you a series of fun questions.`}
				buttons={[
					<Button href="/questionaire">Continue on kiosk</Button>,
					<Button onClick={() => setModal("scan")}>Continue on mobile</Button>,
				]}
				open={modal === "done"}
			/>
			<Modal
				text="Scan QR code on the back of your ticket"
				button={<Button href="/">Done</Button>}
				open={modal === "scan"}
			/>
		</>
	);
}

export default Smile;
