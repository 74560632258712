import styles from "./Figures.module.scss";
import cx from "classnames";

function FigureBodyRight({ modal }) {
	return (
		<div className={cx(styles.main, { [styles.modal]: modal })}>
			<svg viewBox="0 0 353 538" fill="none">
				<path
					d="M302.523 176.267L334.024 158.079L329.456 175.292L339.903 169.26L345.031 150.131L343.6 147.653L342.162 145.162L323.031 140.038L312.531 146.101L329.775 150.72L298.274 168.907L302.523 176.267Z"
					className={styles.fill}
				/>
				<path
					d="M159.943 43.1817C159.943 20.6824 140.474 3.12662 118.095 5.44587L35.0272 14.0544C15.6924 16.0581 0.999985 32.3518 0.999985 51.7902V99.5906C0.999985 122.09 20.4691 139.646 42.8486 137.326L125.916 128.718C145.251 126.714 159.943 110.421 159.943 90.9821V43.1817Z"
					className={styles.item}
				/>
				<rect
					x="108.08"
					y="360.527"
					width="57.0558"
					height="156.996"
					rx="28.5279"
					className={styles.item}
				/>
				<rect
					x="176.221"
					y="379.996"
					width="57.0558"
					height="156.996"
					rx="28.5279"
					className={styles.item}
				/>
				<rect
					x="65.248"
					y="158.05"
					width="57.0558"
					height="156.996"
					rx="28.5279"
					className={styles.item}
				/>
				<path
					d="M89.2598 183.267C89.2598 157.527 114.348 139.259 138.845 147.161L221.912 173.957C237.582 179.012 248.203 193.598 248.203 210.063V373.545C248.203 399.285 223.115 417.553 198.618 409.651L115.551 382.855C99.8805 377.8 89.2598 363.214 89.2598 346.749V183.267Z"
					className={styles.item}
				/>
				<rect
					x="220.352"
					y="196.338"
					width="57.0558"
					height="156.996"
					rx="28.5279"
					className={styles.item}
				/>
				<circle cx="181.711" cy="104.483" r="63.8965" className={styles.item} />
				<rect
					width="8.93675"
					height="20.8109"
					rx="4.46837"
					transform="matrix(-0.991888 -0.127115 -0.127115 0.991888 146.508 69.9509)"
					className={styles.fill}
				/>
				<path
					d="M138.777 114.088C119.75 120.331 119.75 106.061 119.75 106.061"
					strokeWidth="5.94598"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={styles.stroke}
				/>
			</svg>
		</div>
	);
}

export default FigureBodyRight;
