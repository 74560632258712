import { useContext } from "react";
import QuestionContext from "../../contexts/QuestionContext";
import cx from "classnames";
import Controls from "./Controls";
import List from "./shared/List";
import { getNewContext } from "./utils/utils";
import useMatchMedia from "../../utils/useMatchMedia";
import styles from "./Position.module.scss";
import { POSITIONS } from "./utils/data";

function Defensive() {
	return (
		<svg viewBox="0 0 38.28 38.28">
			<path d="M36.82,36.82c-1.95,1.95-5.12,1.95-7.07,0l-10.61-10.61-10.61,10.61c-1.95,1.95-5.12,1.95-7.07,0-1.95-1.95-1.95-5.12,0-7.07l10.61-10.61L1.46,8.54C-.49,6.58-.49,3.42,1.46,1.46S6.58-.49,8.54,1.46l10.61,10.61L29.75,1.46c1.95-1.95,5.12-1.95,7.07,0,1.95,1.95,1.95,5.12,0,7.07l-10.61,10.61,10.61,10.61c1.95,1.95,1.95,5.12,0,7.07Z" />
		</svg>
	);
}

function Offensive() {
	return (
		<svg viewBox="0 0 42.43 42.43">
			<path d="M21.21,42.43C9.52,42.43,0,32.91,0,21.21S9.52,0,21.21,0s21.21,9.52,21.21,21.21-9.52,21.21-21.21,21.21Zm0-32.43c-6.18,0-11.21,5.03-11.21,11.21s5.03,11.21,11.21,11.21,11.21-5.03,11.21-11.21-5.03-11.21-11.21-11.21Z" />
		</svg>
	);
}

const Item = ({ item, className, children, ...props }) => {
	const isDismissed = props.value && props.value !== item.value;
	return (
		<div
			style={item.style || { gridArea: item.area }}
			className={cx(styles.item, className, {
				[styles.offset]: item.offset,
				[styles.dismissed]: isDismissed,
			})}>
			{children}
		</div>
	);
};

function Position(props) {
	const { context, setContext, state } = useContext(QuestionContext);

	const handleClick = ({ value }) => {
		const newContext = getNewContext(context, state, value);
		setContext(newContext);
	};

	const items = [
		{
			title: "Defensive",
			items: POSITIONS.defensive,
		},
		{
			title: "Offensive",
			items: POSITIONS.offensive,
		},
	];

	const isLargeScreen = useMatchMedia("(min-width: 1280px)");

	return !isLargeScreen ? (
		<List items={items} onClick={handleClick} selectedKey="value" {...props}>
			{(item) => <>{item.label}</>}
		</List>
	) : (
		<div className={cx(styles.main, { [styles.hasSelection]: props.value })}>
			<div className={styles.label}>{props.label}</div>
			<div className={styles.inner}>
				<div className={styles.defensive}>
					{POSITIONS.defensive.map((item, index) => {
						return (
							<Item key={index} item={item} {...props}>
								<button onClick={() => handleClick(item)}>
									<span>{item.label}</span>
									<Defensive />
								</button>
							</Item>
						);
					})}
				</div>
				<div className={styles.offensive}>
					{POSITIONS.offensive.map((item, index) => {
						const hasItems = item.items?.length;
						return hasItems ? (
							<div key={index} className={styles.items} style={item.style}>
								{item.items.map((item, index) => {
									return (
										<Item key={index} item={item} {...props}>
											<button onClick={() => handleClick(item)}>
												<Offensive />
												<span>{item.label}</span>
											</button>
										</Item>
									);
								})}
							</div>
						) : (
							<Item key={index} item={item} {...props}>
								<button onClick={() => handleClick(item)}>
									<Offensive />
									<span>{item.label}</span>
								</button>
							</Item>
						);
					})}
				</div>
			</div>
			<Controls
				className={styles.controls}
				canProceed={props.value}
				{...props}
			/>
		</div>
	);
}

export default Position;
