import { useEffect, useState } from "react";
import QuestionContext, {
	defaultData,
	defaultState,
	steps,
} from "../contexts/QuestionContext.js";
import socketHandler from "./socketHandler.js";

function QuestionHandler({ children }) {
	const [context, setContext] = useState(defaultData);
	const [state, setState] = useState(defaultState);

	useEffect(() => {
		socketHandler.init();
	}, []);

	return (
		<QuestionContext.Provider
			value={{ context, setContext, state, setState, steps }}>
			{children}
		</QuestionContext.Provider>
	);
}

export default QuestionHandler;
