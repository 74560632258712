import React, { useContext, useRef, useState, useMemo, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
	DoubleSide,
	Group,
	MeshBasicMaterial,
	ShapeGeometry,
	Mesh,
	MeshPhongMaterial,
	Color,
} from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

// function Shape({ shape, rotation, position, color, opacity, index }) {
// 	if (!position) return null
// 	return (
// 	  <a.mesh rotation={rotation} position={position.to((x, y, z) => [x, y, z + index * 50])}>
// 		<a.meshPhongMaterial color={color} opacity={opacity} side={THREE.DoubleSide} depthWrite={false} transparent />
// 		<shapeGeometry args={[shape]} />
// 	  </a.mesh>
// 	)
//   }

function Field({ props }) {
	const { nodes, materials } = useGLTF("/assets/models/field.glb");

	const material = new MeshPhongMaterial({
		color: new Color("white"),
		side: DoubleSide,
		opacity: 0.2,
		depthWrite: false,
		transparent: true,
	});

	const yellowMaterial = new MeshPhongMaterial({
		color: new Color("white"),
		side: DoubleSide,
		opacity: 0.1,
		depthWrite: false,
		transparent: true,
	});

	return (
		<group
			{...props}
			dispose={null}
			position={[0, -4, 3]}
			scale={[1.5, 1.5, 1.5]}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve001.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve002.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.lines.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve009.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve010.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve011.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve012.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve013.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve014.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve015.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve017.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve018.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve019.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve020.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve021.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve022.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve023.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve024.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve025.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve026.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve027.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve028.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve029.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve030.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve031.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve032.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve033.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve034.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve035.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve036.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve037.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve038.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve039.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve040.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve041.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve042.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve043.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve044.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve045.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve046.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve047.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve048.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve049.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve050.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve051.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve052.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve053.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve054.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve055.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve056.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve057.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve058.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve059.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve060.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Curve061.geometry}
				material={material}
				rotation={[0, -1.571, 0]}
			/>
			{/* <mesh
                castShadow
                receiveShadow
                geometry={nodes.goal2.geometry}
                material={yellowMaterial}
                rotation={[0, -1.571, 0]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.goal1.geometry}
                material={yellowMaterial}
                rotation={[0, -1.571, 0]}
            /> */}
		</group>
	);
}
useGLTF.preload("/assets/models/field.glb");

// useGLTF.preload("/field.glb");

export default Field;
