import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./styles/global.scss";

// Handlers
import DataHandler from "./utils/DataHandler";
import QuestionHandler from "./utils/QuestionHandler";
import FaceHandler from "./utils/FaceHandler";
import IntersectsHandler from "./utils/IntersectsHandler";
import CaptureHandler from "./utils/CaptureHandler";
import PageTransition from "./components/PageTransition";

// Image Capture Pages
import Welcome from "./pages/Welcome";
import Start from "./pages/Start";
import Calibration from "./pages/Calibration";
import HowToPlay from "./pages/HowToPlay";
import Game from "./pages/Game";
import Game2 from "./pages/Game2";
import Game3 from "./pages/Game3";
import Smile from "./pages/Smile";

// Other Pages
import Questionaire from "./pages/Questionaire";
import Teams from "./pages/Teams";

// Components
import Modal from "./components/Modal";
import Button from "./components/Button";
import Upload from "./pages/Upload";

function App() {
	return (
		<RecoilRoot>
			<DataHandler>
				<CaptureHandler>
					<FaceHandler>
						<IntersectsHandler>
							<div className="App">
								<PageTransition>
									<Router>
										<Routes>
											{/* <Route path="/" element={<Welcome />} />
											<Route path="/start" element={<Start />} />
											<Route path="/calibration" element={<Calibration />} /> */}
											<Route path="/" element={<Upload />} />
											<Route path="/how-to-play" element={<HowToPlay />} />
											<Route path="/game" element={<Game />} />
											<Route path="/game2" element={<Game2 />} />
											<Route path="/game3" element={<Game3 />} />
											<Route path="/smile" element={<Smile />} />
											<Route
												path="/questionaire"
												element={
													<QuestionHandler>
														<Questionaire />
													</QuestionHandler>
												}
											/>
											{/* <Route path="/teams" element={<Teams />} /> */}
										</Routes>
										<Modal text="No internet right now, we’ll be back online soon." />
										<Modal
											text="We have an issue. Please try again or ask an attendant for help."
											button={<Button>Try Again</Button>}
										/>
										<Modal
											text="Are you still there?"
											button={<Button>I'm still here</Button>}
											timer
										/>
									</Router>
								</PageTransition>
							</div>
						</IntersectsHandler>
					</FaceHandler>
				</CaptureHandler>
			</DataHandler>
		</RecoilRoot>
	);
}

export default App;
