import React, { useContext, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import {
	MeshToonMaterial,
	DataTexture,
	RedFormat,
	MeshStandardMaterial,
	MeshPhysicalMaterial,
	Vector2,
} from "three";
import Line from "./Line";

import FaceContext from "../contexts/FaceContext";

function Helmet(props) {
	const yRef = useRef();
	const xRef = useRef();
	const { nodes, materials } = useGLTF("/assets/models/helmet.glb");
	const colors = new Uint8Array(3);
	const { faceDetect, getRotation } = useContext(FaceContext);
	const rotation = useRef(new Vector2());

	for (let c = 0; c <= colors.length; c++) {
		colors[c] = (c / colors.length) * 256;
	}

	const gradientMap = new DataTexture(colors, colors.length, 1, RedFormat);
	gradientMap.needsUpdate = true;

	// const toonMat = new MeshToonMaterial({
	// 	gradientMap: gradientMap,
	// 	// flatShading: true,
	// 	transparent: true,
	// });

	const toonMat = new MeshPhysicalMaterial({
		color: 0xffffff,
		roughness: 0.1,
		metalness: 1,
		transparent: true,
		sheen: 1,
		// depthWrite: false,
		// transmission: 0.5,
	});

	// Subscribe this component to the render-loop, rotate the mesh every frame
	useFrame((state, delta) => {
		// console.log(faceContext.rotation.y);

		if (!getRotation.current) return;

		const faceRot = getRotation.current();

		const vec2 = new Vector2(faceRot.x, faceRot.y);

		// console.log(rotation);

		rotation.current.lerp(vec2, 0.3);

		// console.log(rotation.current.x, rotation.current.y);

		// rotation.current.x = rotation.x * 0.5;

		yRef.current.rotation.y = rotation.current.y; //(faceContext.rotation.y / 360) * Math.PI * -2;
		xRef.current.rotation.x = -rotation.current.x; //(faceContext.rotation.x / 360) * Math.PI * -2;
	});

	// Return view, these are regular three.js elements expressed in JSX

	return (
		<group ref={yRef} {...props} dispose={null}>
			<group ref={xRef} dispose={null}>
				<group rotation={[0, Math.PI, 0]} scale={[0.7, 0.7, 0.7]}>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Helmet.geometry}
						// material={materials["Helmet Material"]}
						material={toonMat}
						position={[0, 0.282, 0]}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Helmet_Padding.geometry}
						// material={materials["Helmet Padding Material"]}
						material={toonMat}
						position={[0, 0.282, 0]}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Helmet_Strap.geometry}
						// material={materials["Stap material"]}
						material={toonMat}
						position={[0, 0.282, 0]}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Helmet_Grill.geometry}
						// material={materials["Helmet Grill material"]}
						material={toonMat}
						position={[1.203, 1.052, 3.108]}
						rotation={[Math.PI / 2, 0, 0]}
					/>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Helmet_Fixings.geometry}
						// material={materials["Helmet Fixings Material"]}
						material={toonMat}
						position={[0, 0.282, 0]}
					/>
				</group>
				<Line />
			</group>
		</group>
	);
}

useGLTF.preload("/assets/models/helmet.glb");

export default Helmet;
