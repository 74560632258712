import Canvas from "../three-components/Canvas";
import { useRecoilState } from "recoil";
import { gameData, gameRunning } from "../utils/store";
import { useEffect } from "react";
import Button from "../components/Button";
import Layout from "../components/capture/Layout";
import FigureBodyLeft from "../components/capture/figures/FigureBodyLeft";

const MODAL = {
	text: "Stand with your body facing slightly towards the left of the screen",
	figure: FigureBodyLeft,
};

function Game() {
	const settings = {
		targets: [0, 1, 2],
		imageType: "body-left",
		nextPage: "/game3",
	};
	const [data, setData] = useRecoilState(gameData);
	const [isRunning, setIsRunning] = useRecoilState(gameRunning);

	useEffect(() => {
		setIsRunning(false);
		setData(settings);
	}, []);

	return (
		<Layout modal={MODAL}>
			<Canvas></Canvas>
			{/*Temporary Navigation*/}
			{/* <div style={{ position: "absolute", bottom: "60px", left: "60px" }}>
				<Button href="/game3">Next</Button>
			</div> */}
		</Layout>
	);
}

export default Game;
