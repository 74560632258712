import styles from "./Layout.module.scss";

function Layout(props) {
	return (
		<div className={styles.main}>
			<div className={styles.inner}>
				<label className={styles.label}>{props.label}</label>
				{props.children}
			</div>
			<div className={styles.controls}>{props.controls}</div>
		</div>
	);
}

export default Layout;
