import styles from "./Button.module.scss";
import TransitionContext from "../contexts/TransitionContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

function Button({ children, href, onClick = false, placeholder }) {
	const [context, setContext] = useContext(TransitionContext);
	const navigate = useNavigate();

	const handleClick = () => {
		setContext(true);
		setTimeout(() => {
			navigate(href);
		}, 1000);
	};

	return (
		<button
			className={cx(styles.button, { [styles.placeholder]: placeholder })}
			onClick={onClick || handleClick}>
			{children}
		</button>
	);
}

export default Button;
