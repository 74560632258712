import styles from "./Figures.module.scss";
import cx from "classnames";

function FigureBodyLeft({ modal }) {
	return (
		<div className={cx(styles.main, { [styles.modal]: modal })}>
			<svg viewBox="0 0 341 540" fill="none">
				<path
					d="M179.617 43.4063C179.617 20.7931 199.185 3.14855 221.677 5.47953L305.155 14.1305C324.587 16.1444 339.354 32.5204 339.354 52.0572V100.093C339.354 122.706 319.786 140.351 297.294 138.02L213.817 129.369C194.384 127.355 179.617 110.979 179.617 91.4423V43.4063Z"
					className={styles.item}
				/>
				<rect
					x="-1"
					y="1"
					width="57.3469"
					height="157.78"
					rx="28.6735"
					transform="matrix(-1 0 0 1 164.875 380.22)"
					className={styles.item}
				/>
				<rect
					x="-1"
					y="1"
					width="57.3469"
					height="157.78"
					rx="28.6735"
					transform="matrix(-1 0 0 1 239.874 360.655)"
					className={styles.item}
				/>
				<rect
					x="-1"
					y="1"
					width="57.3469"
					height="157.78"
					rx="28.6735"
					transform="matrix(-1 0 0 1 282.917 157.18)"
					className={styles.item}
				/>
				<path
					d="M259.787 177.595C259.787 153.509 237.732 135.457 214.121 140.217L130.644 157.047C112.849 160.635 100.05 176.272 100.05 194.425V361.109C100.05 385.195 122.105 403.247 145.716 398.486L229.193 381.656C246.989 378.069 259.787 362.432 259.787 344.279V177.595Z"
					className={styles.item}
				/>
				<rect
					x="-1"
					y="1"
					width="57.3469"
					height="157.78"
					rx="28.6735"
					transform="matrix(-1 0 0 1 127.05 189.136)"
					className={styles.item}
				/>
				<circle
					cx="65.2164"
					cy="65.2164"
					r="64.2164"
					transform="matrix(-1 0 0 1 245.135 26.0947)"
					className={styles.item}
				/>
				<rect
					x="214.957"
					y="56.3801"
					width="8.96996"
					height="20.8883"
					rx="4.48498"
					transform="rotate(-7.30292 214.957 56.3801)"
					className={styles.fill}
				/>
				<path
					d="M222.716 100.683C241.814 106.95 241.814 92.6265 241.814 92.6265"
					className={styles.stroke}
					strokeWidth="5.96808"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M54.2054 163.829L22.7038 145.641L39.8944 140.991L29.4479 134.96L10.3171 140.084L8.88648 142.562L7.44851 145.052L12.5765 164.182L23.077 170.245L18.4546 153.001L49.9563 171.188L54.2054 163.829Z"
					className={styles.fill}
				/>
			</svg>
		</div>
	);
}

export default FigureBodyLeft;
