import { createContext } from "react";

const defaultData = {
    users: [
        {
            id: 1,
            name: "John Doe",
        },
    ],
};

const DataContext = createContext(defaultData);

export { defaultData };

export default DataContext;
