import React from "react";
import { atom, selector } from "recoil";
// import Capture from "./Capture";
// import Face from "./Face";

const gameData = atom({
	key: "gameData",
	default: {},
});

const modelName = atom({
	key: "modelName",
	default: "default",
});

const gameRunning = atom({
	key: "gameRunning",
	default: false,
});

// const capture = atom({
// 	key: "capture",
// 	default: new Capture(),
// });

// const captureImage = selector({
// 	key: "captureImage", // unique ID (with respect to other atoms/selectors)
// 	get: ({ get }) => {
// 		const text = get(textState);

// 		return text.length;
// 	},
// });

export { gameData, modelName, gameRunning };
