import { useEffect, useState } from "react";
import DataContext, { defaultData } from "../contexts/DataContext.js";
import socketHandler from "./socketHandler.js";

function DataHandler({ children }) {
    const [context, setContext] = useState(defaultData);

    useEffect(() => {
        socketHandler.init();
    }, []);

    return (
        <DataContext.Provider value={[context, setContext]}>
            {children}
        </DataContext.Provider>
    );
}

export default DataHandler;
