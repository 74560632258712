import { useContext, useEffect, useState } from "react";
import QuestionContext from "../../contexts/QuestionContext";
import Layout from "./shared/Layout";
import Controls from "./Controls";
import { getNewContext } from "./utils/utils";
import cx from "classnames";
import styles from "./Input.module.scss";

const MESSAGES = {
	email: "Please enter a valid email address",
	zipcode: "Please enter a valid 5 digit zip code",
	text: "Oops! It looks like you've reached the maximum character limit",
};

const ZIPCODE_CHECKBOX = "I reside outside of US";
const MAX_CHARACTERS = 25;

function Input(props) {
	const { context, setContext, state, setState } = useContext(QuestionContext);
	const [hasError, setHasError] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const hasCheckbox = props.type === "zipcode";
	const isNotUS = props.value !== ZIPCODE_CHECKBOX;
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const zipCodeRegex = /^\d{5}(-\d{4})?$/;
	const message = MESSAGES[props.type];

	useEffect(() => {
		liveValidate(props.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateContext = (value) => {
		const newContext = getNewContext(context, state, value);
		setContext(newContext);
		setHasError(false);
	};

	const handleNext = () => {
		setState((prevState) => ({
			...prevState,
			currentStep: props.nextStep,
		}));
	};

	const handleInputChange = (event) => {
		const newValue = event.target.value;
		liveValidate(newValue);
		updateContext(newValue);
	};

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;
		const newValue = isChecked ? ZIPCODE_CHECKBOX : "";
		setIsValid(false);
		updateContext(newValue);
	};

	const validateField = (regex) => {
		if (!regex.test(props.value)) {
			setHasError(true);
		} else {
			setHasError(false);
			handleNext();
		}
	};

	const validate = () => {
		switch (props.type) {
			case "email":
				validateField(emailRegex);
				break;
			case "zipcode":
				if (isNotUS) {
					validateField(zipCodeRegex);
				} else {
					handleNext();
				}
				break;
			default:
				if (props.value.length > MAX_CHARACTERS) {
					setHasError(true);
				} else {
					setHasError(false);
					handleNext();
				}
				break;
		}
	};

	const liveValidateField = (regex, value) => {
		if (!regex.test(value)) {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	};

	const liveValidate = (value) => {
		switch (props.type) {
			case "email":
				liveValidateField(emailRegex, value);
				break;
			case "zipcode":
				liveValidateField(zipCodeRegex, value);
				break;
			default:
				if (!value.length || value.length > MAX_CHARACTERS) {
					setIsValid(false);
				} else {
					setIsValid(true);
				}
				break;
		}
	};

	return (
		<Layout
			label={props.label}
			controls={
				<Controls
					className={styles.controls}
					handleNext={validate}
					canProceed={props.value}
					{...props}
				/>
			}>
			<input
				type={props.type}
				value={isNotUS ? props.value : ""}
				onChange={handleInputChange}
				placeholder={props.placeholder}
				className={cx(styles.input, {
					[styles.error]: hasError,
					[styles.valid]: isValid,
				})}
			/>
			<div className={cx(styles.message, { [styles.error]: hasError })}>
				{message && <p>{message}</p>}
			</div>
			{hasCheckbox && (
				<div className={styles.checkbox}>
					<input
						id="checkbox"
						type="checkbox"
						checked={props.value === ZIPCODE_CHECKBOX}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor="checkbox" className={styles.checkboxLabel}>
						<span className={styles.checkboxLabel_a}></span>
						<span className={styles.checkboxLabel_b}>{ZIPCODE_CHECKBOX}</span>
					</label>
				</div>
			)}
		</Layout>
	);
}

export default Input;
