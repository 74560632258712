// import HeightCheck from "../components/HeightCheck";
import Layout from "../components/capture/Layout";
import Button from "../components/Button";
import FigureStart from "../components/capture/figures/FigureStart";
import styles from "./Calibration.module.scss";

const MODAL = {
	text: "Press the buttons below the screen to move the screen so that your face fills the oval",
	figure: FigureStart,
};

function Calibration() {
	return (
		<Layout modal={MODAL}>
			{/*<HeightCheck />*/}
			{/*Temporary Navigation*/}
			<svg className={styles.main} viewBox="0 0 460 579" fill="none">
				<ellipse
					cx="230"
					cy="289.016"
					rx="225"
					ry="284.016"
					stroke="white"
					strokeWidth="10"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="20 70"
				/>
			</svg>
			<div style={{ position: "absolute", bottom: "60px", left: "60px" }}>
				<Button href="/how-to-play">Next</Button>
			</div>
		</Layout>
	);
}

export default Calibration;
