import InfoModal from "./InfoModal";
import styles from "./InfoBox.module.scss";
import { useState } from "react";
import { gameData, gameRunning } from "../../utils/store";
import { useRecoilState } from "recoil";

function Icon() {
	return (
		<svg viewBox="0 0 28.75 143.75">
			<path d="M24.62,139.62c-2.76,2.75-6.17,4.13-10.24,4.13s-7.49-1.38-10.24-4.13c-2.76-2.76-4.13-6.17-4.13-10.24v-57.5c0-4.07,1.38-7.49,4.13-10.24,2.75-2.76,6.17-4.13,10.24-4.13s7.49,1.38,10.24,4.13c2.75,2.75,4.13,6.17,4.13,10.24v57.5c0,4.07-1.38,7.49-4.13,10.24Zm0-115c-2.76,2.76-6.17,4.13-10.24,4.13s-7.49-1.38-10.24-4.13C1.38,21.86,0,18.45,0,14.38S1.38,6.89,4.13,4.13C6.89,1.38,10.3,0,14.38,0s7.49,1.38,10.24,4.13c2.75,2.76,4.13,6.17,4.13,10.24s-1.38,7.49-4.13,10.24Z" />
		</svg>
	);
}

function InfoBox({ modal, figure }) {
	const [modalOpen, setModalOpen] = useState(true);
	const [isRunning, setIsRunning] = useRecoilState(gameRunning);

	return (
		<>
			<InfoModal
				open={modalOpen}
				onClick={() => {
					setModalOpen(false);
					setTimeout(() => {
						setIsRunning(true);
					}, 1000);
				}}
				data={modal}
			/>
			<button
				onClick={() => {
					setModalOpen(true);
					setIsRunning(false);
				}}
				className={styles.main}>
				<div className={styles.icon}>
					<Icon />
				</div>
				<div className={styles.figure}>
					<modal.figure />
				</div>
			</button>
		</>
	);
}

export default InfoBox;
