import {
	FaceLandmarker,
	FaceDetector,
	FilesetResolver,
	DrawingUtils,
} from "@mediapipe/tasks-vision";

class Face {
	rotation = { x: 0, y: 0, z: 0 };
	faceLandmarks = [];
	detectionData = { dx: 0, dy: 0, dw: 0, dh: 0, px: 0, py: 0, pw: 0, ph: 0 };
	runningMode = "VIDEO"; //"IMAGE" //
	rectSize = 1024;
	landmarkDetectTime = null;
	nocrop = false;
	videoSize = {
		width: 512,
		height: 512,
	};
	faceDetector = null;
	faceLandmarker = null;

	constructor(video) {
		this.streamImg = video;
		// this.init();
	}

	init = async () => {
		const { faceLandmarker, faceDetector } = await this.createFaceLandmarker();

		this.faceLandmarker = faceLandmarker;
		this.faceDetector = faceDetector;
	};

	createFaceLandmarker = async () => {
		const filesetResolver = await FilesetResolver.forVisionTasks(
			"https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
		);
		const faceLandmarker = await FaceLandmarker.createFromOptions(
			filesetResolver,
			{
				baseOptions: {
					modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
					delegate: "GPU",
				},
				outputFaceBlendshapes: false,
				runningMode: this.runningMode,
				numFaces: 1,
				outputFacialTransformationMatrixes: true,
			}
		);

		const faceDetector = await FaceDetector.createFromOptions(filesetResolver, {
			baseOptions: {
				modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
				delegate: "GPU",
			},
			runningMode: this.runningMode,
		});

		return { faceDetector, faceLandmarker };
	};

	faceDetect = () => {
		// console.log(this.faceDetector, this.streamImg);

		if (!this.faceDetector) return false;

		this.startTimeMs = performance.now();

		const detections = this.faceDetector.detectForVideo(
			this.streamImg,
			this.startTimeMs
		).detections;

		if (detections[0]) {
			const {
				originX: x,
				originY: y,
				width,
				height,
			} = detections[0].boundingBox;

			this.detectionData = {
				videoHeight: this.streamImg.videoHeight,
				videoWidth: this.streamImg.videoWidth,
				x,
				y,
				width,
				height,
			};

			return this.detectionData;
		}

		return false;
	};

	getRotation = () => {
		return this.rotation;
	};

	testVideo = () => {
		const { videoWidth, videoHeight } = this.streamImg;
		// console.log(videoWidth, videoHeight);
		// debugger;
		if (videoWidth === 0 || videoHeight === 0) return false;
		else return true;
	};

	predictWebcam = () => {
		if (!this.faceLandmarker) return {};
		if (!this.testVideo()) return {};
		this.startTimeMs = performance.now();

		// console.log(this.streamImg);

		// debugger;

		this.results = this.faceLandmarker.detectForVideo(
			this.streamImg,
			this.startTimeMs
		);

		this.landmarkDetectTime = performance.now() - this.startTimeMs;

		if (this.results.facialTransformationMatrixes[0]) {
			const mat = this.results.facialTransformationMatrixes[0].data;

			const x = mat[0];
			const y = mat[1];
			const z = mat[2];

			const upX = mat[4];
			const upY = mat[5];
			const upZ = mat[6];

			const forwardX = mat[8];
			const forwardY = mat[9];
			const forwardZ = mat[10];

			this.rotation.x = Math.atan2(upZ, forwardZ);
			this.rotation.y = Math.atan2(
				-z,
				Math.sqrt(upZ * upZ + forwardZ * forwardZ)
			);
			this.rotation.z = Math.atan2(y, x);
			this.rotation.y *= 1.3;
		}

		return this.rotation;
	};

	update = () => {};
}

export default Face;
