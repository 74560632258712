import Controls from "../Controls";
import styles from "./List.module.scss";
import cx from "classnames";

function List({ items, selectedKey, value, onClick, children, ...props }) {
	return (
		<div className={styles.main}>
			<div className={styles.head}>
				<span className={styles.label}>{props.label}</span>
			</div>
			<div className={styles.list}>
				{items.map((item, index) => {
					return (
						<div className={cx({ [styles.border]: !item.title })} key={index}>
							{item.title && <div className={styles.title}>{item.title}</div>}
							{item.items.map((item, index) => {
								const selectedValue = selectedKey ? item[selectedKey] : item;
								const isDismissed = value && value !== selectedValue;
								const isSelected = value && value === selectedValue;
								return (
									<button
										key={index}
										onClick={() => onClick(item)}
										className={cx(styles.item, {
											[styles.selected]: isSelected,
											[styles.dismissed]: isDismissed,
										})}>
										{children(item)}
									</button>
								);
							})}
						</div>
					);
				})}
			</div>
			<Controls className={styles.controls} canProceed={value} {...props} />
		</div>
	);
}

export default List;
